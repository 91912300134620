.entry-wrap {
    display: flex;
    width: 90vw;
    .entry-name {
        color: var(--color-light);
        width: 30%;
    }
}
.reload {
    min-width: auto;
    padding: .5rem;
    border-radius: 10px;
}

.adminWrapper {
    .selector {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        padding: 0 10% 0 2rem;
        box-sizing: border-box;
    }
    .wrap {
        display: flex;
        width: 90vw;
        box-sizing: border-box;
        .label {
            width: 30%;
            display: flex;
            align-items: center;
            font-weight: 500;
        }
        .value {
            width: 70%;
            background-color: var(--color-darker);
            padding: 1rem;
            border-radius: 20px;
            margin: .5rem 0 .5rem 1rem;
        }
    }
    h2 {
        margin-top: 1.5rem;
    }
}
.select-job {
    display: flex;
    .job {
        padding: .5rem 1rem;
        border: 1px solid var(--color-light);
        text-align: center;
        cursor: pointer;
    }
    .selected {
        background-color: var(--color-red);
    }
}
.table-wrap {
    max-width: 100vw;
    box-sizing: border-box;
}
table.applications {
    border-collapse: collapse;
    width: 100%;
    margin: 2rem 1rem;
    td, th {
        vertical-align: top;
        text-align: left;
        padding: .2rem;
        border: 1px solid var(--color-light);
        font-size: smaller;
    }
    .selected td {
        background-color: var(--color-red);
    }
}
.detail-wrap {
    box-sizing: border-box;
    margin: 2rem;
    max-width: 1200px;
    .detail-title {
        font-weight: bold;
        font-size: large;
    }
}