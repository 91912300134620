@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500&display=swap');

:root {
  --font-primary: "Nunito", sans-serif;
  --color-black: #080f11;
  --color-text: #fff;
  --color-light: #65eefc;
  --color-dark: #9fb3d3;
  --color-darker: #19202d;
  --color-red: #fb3640;
  --color-reder: #e6323b;
  --color-green: #0F9C00;
  --elevate-1: 0px 11px 16px 7px rgba(0,0,0,1);
  --elevate-2: 0px 4px 10px 0px rgba(0,0,0,1);
}

html, body {
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  background-color: var(--color-black);
  color: var(--color-text);
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6rem;
  font-size: 1.1rem;
}

#root, .App {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
