#add-new-row-wrap {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 9999999;
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
    &.closed {
        transform: translateX(130%) scale(0);
        opacity: 0;
    }
    .add-new-row {
        background-color: var(--color-darker);
        padding: 2rem;
        border-radius: 20px;
        box-shadow: var(--elevate-1);
    }
    .icon-row {
        display: flex;
        justify-content: flex-end;
        svg {
            margin: 1rem;
            width: 50px;
            height: 50px;
        }
    }
}