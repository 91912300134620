header {
    width: 100vw;
    max-height: 50px;
    background-color: var(--color-darker);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 2rem;
    font-weight: 400;
    color: var(--color-light);
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
}
#logo img {
    max-height: 30px;
    margin-top: 10px;
}
.user-info {
    position: relative;
}
.user-menu {
    position: absolute;
    right: -1rem;
    background-color: var(--color-darker);
    padding: 1rem;
    border-radius: 20px;
    box-shadow: var(--elevate-1);
    &.closed {
        display: none;
    }
    & li {
        list-style-type: none;
        color: var(--color-text);
        text-align: right;
        padding: .4rem .5rem;
        &:hover {
            color: var(--color-light)
        }
    }
}
.user-icon {
    max-height: 40px;
    margin-top: 5px;
    border-radius: 50%;
    border: 1px solid var(--color-dark);
}