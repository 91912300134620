  .login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    .back {
      top: 0;
    }
  }
  .login__google, .login__google:hover {
    background-color: #4285f4;
    border-color: #4285f4;
  }
