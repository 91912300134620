.card.project-funding {
    position: relative;
    width: 80%;
    max-width: 900px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 400ms ease-in, opacity 400ms ease-in;
    &.left {
        transform: translateX(-130vw);
        opacity: 0;
        position: absolute;
    }
    &.right {
        transform: translateX(130vw);
        opacity: 0;
        position: absolute;
    }
}
.card-buttons {
    display: flex;
    justify-content: space-between;
}
.save-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--color-red);
    cursor: pointer;
    &:hover {
        color: var(--color-reder)
    }
}
.finalize {
    border: 1px solid var(--color-text);
    border-radius: 20px;
    padding: .5rem 1rem;
    background-color: var(--color-red);
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        background-color: var(--color-reder);
    }
}
.finalize-text {
    position: absolute;
    width: 900px;
    right: 0;
    padding-top: 2rem;
    font-weight: bold;
}