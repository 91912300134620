#tokenomics-table {
    width: 100%;
    border-collapse: collapse;
    font-size: .8rem;
    td, th {
        border: 1px solid white;
        padding: .3rem .5rem;
    }
    .t-amount, .t-perc, .t-price, .t-raised, .t-raise, .t-val {
        text-align: right;
    }
    .t-init, .t-clif, .t-vest, .t-delete {
        text-align: center;
    }
    .summary td {
        background-color: var(--color-light);
        color: var(--color-black);
        font-weight: 600;
        border-color: var(--color-dark);
    }
    td.t-delete {
        color: var(--color-red);
        svg {
            cursor: pointer;
        }
    }
}
.icon-row {
    margin: 1rem;
    text-align: right;
}
.display td:nth-child(12), .display th:nth-child(12) {
    display: none;
}
.cap-data .input-wrap {
    display: flex;
    & > div {
        min-width: 150px;
        text-align: right;
    }
}
.cap-data .input-row:first-child {
    background-color: var(--color-light);
    color: var(--color-black);
    padding: 5px;
    font-weight: 500;
}
.pie {
    max-width: 500px;
}