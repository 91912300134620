.position {
    background-color: var(--color-red);
    padding: 2rem;
    margin: 1rem auto;
    max-width: 600px;
    border-radius: 20px;
    font-size: larger;
    font-weight: 400;
}
.position-detail {
    max-width: 900px;
    text-align: left;
}
.apply {
    margin-top: 100px;
}