.App {
  display: flex;
  flex-direction: column;
}
main {
  min-height: calc(100vh - 10rem);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin: 5rem 0;
}
.card {
  background-color: var(--color-darker);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: var(--elevate-1);
}
a {
  color: var(--color-light);
  text-decoration: none;
}
a:hover {
  color: var(--color-text);
}
button, .button {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}
hr {
  width: 80%;
  border: none;
  height: 1px;
  background-color: var(--color-light);
  opacity: 0.5;
}
h1,
h2,
h3 {
  margin: 0 1rem 1rem;
  text-align: center;
}
.pointer {
  cursor: pointer;
}

input,
button, select, textarea {
  box-sizing: border-box;
  background-color: var(--color-text);
  color: var(--color-black);
  padding: 0.7rem 1rem;
  margin: 0.5rem 0;
  min-width: 300px;
  border: 0;
  border: 2px solid var(--color-darker);
  border-radius: 4px;
  outline: none;
  font-size: 1rem;
  &::placeholder {
    color: var(--color-darker);
  }
  &:focus {
    border-color: var(--color-red);
  }
}
select option:first-child {
  display: none;
}
textarea {
  width: 100%;
  height:  10rem;
}
input:valid, select:valid, textarea:valid {
  border-color: var(--color-green) !important;
}
input:invalid, select:invalid, textarea:invalid {
  border-color: var(--color-red)
}

button,
.button {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-text);
  padding: .8rem 1.5rem;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 20px;
  &:hover {
    background-color: var(--color-reder);
  }
  &.button-small {
    min-width: auto;
  }
  &.back {
    position: absolute;
    top: 50px;
    display: flex;
    width: 50px;
    min-width: auto;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

.icon-button {
  color: var(--color-red);
  background-color: var(--color-text);
  border: 1px solid var(--color-text);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: var(--elevate-2);
  &:hover {
    color: var(--color-reder);
  }
  &.green {
    background-color: var(--color-darker);
    color: var(--color-light);
    &:hover {
      color: var(--color-dark)
    }
  }
}

.input-row {
  display: flex;
  align-items: center;
  margin: .2rem 1rem;
  label, .div-label {
    box-sizing: border-box;
    width: 33%;
    max-width: 33%;
    padding-right: 1rem;
  }
  .input-wrap {
    width: 77%;
    input {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .radio-wrap {
    display: flex;
    width: 150px;
    max-width: 300px;
    input {
      min-width: auto;
    }
  }
  &.reverse {
    flex-direction: row-reverse;
    .input-wrap {
      width: 100px;
    }
    label {
      width: 100%;
      max-width: none;
    }
    input {
      min-width: auto;
    }
  }
}
.form-err {
  font-size: .9rem;
  color: var(--color-light);
  margin: -0.5rem 0 0 .5rem;
}
.reverse .form-err {
  position: absolute;
}
.warn {
  color: var(--color-red);
}
.center {
  text-align: center;
}

h1 {
  line-height: 2.4rem;
}

.content {
  padding: 2rem;
  margin: 60px 0 150px;
  width: 100%;
  max-width: 1200px;
  text-align: center;
}

.narrow {
  max-width: 600px;
}

.wrap {
  display: flex;
  .col {
    display: flex;
    width: 50%;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 600px) {
  .wrap {
    flex-direction: column;
    .col {
      width: 100%;
    }
  }
  .input-row {
    flex-direction: column;
    label, .input-wrap {
      width: 100%;
      max-width: none;
    }
  }
  div {
    box-sizing: border-box;
  }
}